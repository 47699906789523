.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  /* Styles for small screens */

  .customGptText {
    font-size: 1.4vh;
    color: #ECDCB1;
    padding-top: 1px;
    white-space: nowrap;
    font-family: tektur;
  }

  .MuiAccordionDetails-root {
    justify-content: center;
  }

  .card-style {
    width: 90%;
    background-color: #AAAFBF;
    margin: 10px;
  }

  .button-bot-link {
    margin: 3px;
  }

  .box-padding {
    padding-bottom: 20px;
  }

  .MuiList-root {
    display: flex;
    width: 50vh;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .MuiButton-root {
    height: 5vh;
    width: 200px;
    color: black;
    background: #ECDCB1;
  }

  .typography-title {
    font-size: 1rem;
    color: #ECDCB1;
    font-family: tektur;
    align-self: center;
  }

  .typography-bot {
    font-family: tektur;
    font-size: small;
    background: #ECDCB1;
  }

  .img-banner {
    width: 100%;
    padding-bottom: 5vh;
  }

  .img-footer {
    /* display: none; */
    padding-top: 70px;
    width: 100%;
  }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  /* Styles for medium screens */

  .card-style {
    width: 20vh;
    background-color: #AAAFBF;
    margin: 10px;
  }

  .box-padding {
    padding-bottom: 100px;
  }

  .button-bot-link {
    margin: 7px;
  }

  .MuiList-root {
    display: flex;
    width: 100vh;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .MuiButton-root {
    height: 5vh;
    width: 20vh;
    color: black;
    background: #ECDCB1;
  }

  .typography-title {
    font-size: 3rem;
    color: #ECDCB1;
    font-family: tektur;
    align-self: center;
  }

  .typography-bot {
    font-family: tektur;
    background: #ECDCB1;
  }

  .img-banner {
    width: 100%;
  }

  .img-footer {
    padding-top: 100px;
    width: 100%;
  }
}

@media screen and (min-width: 1025px) {
  /* Styles for large screens (e.g., desktops) */

  .customGptButton {
    height: 3vh,
  }

  .customGptText {
    font-size: 2rem;
    color: #ECDCB1;
    padding-top: 1px;
    text-wrap: nowrap;
    font-family: tektur;
  }

  /* .MuiTypography-root {
    font-size: 1vh,
  } */

  .card-style {
    width: 20vh;
    background-color: #AAAFBF;
    margin: 10px;
  }

  .box-padding {
    padding-bottom: 150px;
  }

  .button-bot-link {
    margin: 10px;
  }
  
  .MuiList-root {
    width: 150vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .MuiButton-root {
    height: 4.5vh;
    width: 270px;
    color: black;
    background: #ECDCB1;
  }

  .typography-title {
    font-size: 5rem;
    color: #ECDCB1;
    font-family: tektur;
    align-self: center;
  }

  .typography-bot {
    font-family: tektur;
    background: #ECDCB1;
  }

  .img-banner {
    width: 100%;
  }

  .img-footer {
    padding-top: 200px;
    width: 100%;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiCollapse-root {
  width: 100%;
}

.MuiAccordionDetails-root {
  padding: 0px;
}